<template lang="pug">
div(:class="{'z-color-select': true, 'z-color-select_dark':dark}")
    button(class="z-color-select__activator" @click="startSelect" ref="refActivator")
        div(:class="['z-color-select__activator__container']")
            mdicon(name="format-color-text")
            div(:class="modelValue")
                mdicon(name="color-helper")
    div(class="z-color-select__list" v-show="isOpen" ref="refList")
        div(v-for="(colorsRow, index) in colors" :key="`color_${index}`" class='z-color-row')
            div(v-for="(color, index) in colorsRow" :key="`color_${index}`" :class="['z-color-select__list__item', color]" @click="selectColor(color)")
                mdicon(name="square")
                mdicon(v-if="color===modelValue" name="square-medium" class="z-color-select__list__item_selected-dot")
    span(v-html="label" class="z-color-select__label" v-if="label")
</template>

<script setup>
import { defineProps, defineEmits, ref, onBeforeUnmount } from 'vue'

const props = defineProps({
    modelValue:{},
    label:{},
    dark:{default:false}
})

const emit = defineEmits(['update:modelValue', 'change'])

const colors = ref([
    ['violet', 'persianblue', 'pink'],
    ['green', 'darkgeen', 'teal'],
    ['navy', 'blue', 'water'],
    ['darkred', 'brown', 'orange'],
    ['red', 'neutral', 'black']
])

const isOpen = ref(false)
const refList = ref(null)
const refActivator = ref(null)

onBeforeUnmount(()=>destroyOnClickOutside())

function registerOnClickOutside () {
    window.addEventListener('click', closeOnclickOutside)
}

function destroyOnClickOutside () {
    window.removeEventListener('click', closeOnclickOutside)
}

function closeOnclickOutside (event) {
    if (isOpen.value) {
        if (!refList.value.contains(event.target) && !refActivator.value.contains(event.target)) {
            stopSelect()
        }
    }
}

function selectColor (color) {
    emit('update:modelValue', color)
    emit('change')
    isOpen.value = false
}

function startSelect () {
    isOpen.value = true
    registerOnClickOutside()
}

function stopSelect () {
    isOpen.value = false
    destroyOnClickOutside()
}

// watch(
//     () => props.modelValue,
//     (value) => {
//         props.modelValue = value
//     }
// )


</script>

<style scoped>
    .z-color-select__activator__container ::v-deep(.mdi){
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    .z-color-select ::v-deep(svg){
        /*position: absolute;*/
    }

    .z-color-select{
        width: 100%;
        height: 100%;
        position: relative;
        display: flex;
        max-height: var(--block-height-big);
    }

    .z-color-select__label{
        /*margin-left: var(--indent-size);*/
        font-size: var(--font-size);
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .z-color-select__activator{
        position: relative;
        min-width: 2.5rem;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .z-color-select__activator__container{
        margin: var(--indent-size);
        position: relative;
    }

    .z-color-select__activator:hover{
        filter: var(--transparent-hover-filter);
        background: var(--transparent-hover-background);
    }

    .z-color-select__activator_helper{
        position: absolute;
        left: 0;
    }

    .z-color-select__list{
        position: absolute;
        top: auto;
        bottom: 0.5rem;
        height: fit-content;
        background: var(--editor-background);
        z-index: 2;
        display: flex;
        cursor: pointer;
    }

    .z-color-select__list__item {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .z-color-select__list__item:hover{
        background: var(--primary-color);
    }

    .mdi-square-medium{
        position: absolute;
        color: var(--alter-text-color);
    }

    .z-color-select_dark:hover{
        filter: brightness(0.9);
    }
</style>
