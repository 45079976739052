<template lang="pug">
div(class="profile__main")
    ul(class="profile__main__tabs")
        li(v-for="(profileTab, tabKey) in profileTabs" :key="`profile_tab_${tabKey}`" :class="{profile__main__tabs__tab:true, profile__main__tabs__tab_active:(currentTab===tabKey)}")
            button(@click="selectTab(tabKey)") {{profileTab.title}}
    component(v-bind:is="currentComponent.component " class="profile__main__view")
</template>

<script setup>

import { ref, shallowRef, computed, defineProps, defineEmits } from 'vue'

import ProfileGeneral from './Profile-general'
import ProfileChat from './Profile-chat'
import ProfileForum from './Profile-forum'
import ProfileUser from './Profile-user'
import Panels from '../~panels'
import Blank from '../Blank'

const emit = defineEmits(['append_to_route'])
const props = defineProps(['panel'])

const profileTabs = shallowRef({
    general: {title: "Общие",   component:ProfileGeneral},
    chat:    {title: "Чат",     component:ProfileChat},
    forum:   {title: "Форум",   component:ProfileForum},
    user:    {title: "Профиль", component:ProfileUser},
})

const currentTab = ref('general')

function readProps () {
    if ('tab' in props.panel) {
        currentTab.value = props.panel.tab
    }
}
readProps()

const currentComponent = computed(()=>profileTabs.value[currentTab.value])

function selectTab (tabKey) {
    currentTab.value = tabKey
    emit('append_to_route', { tab: tabKey })
}
</script>

<style>
    .profile__main{
        height: 100%;
        display: flex;
        flex-direction: column;
    }

    .profile__main__tabs {
        min-height: var(--block-height-big);
        line-height: var(--block-height-big);
        width: 100%;
        flex: 0;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .profile__main__tabs__tab{
        margin: 0 var(--indent-size);
    }

    .profile__main__tabs__tab>button{
        border-bottom: var(--inactive-tab-border-color) var(--tab-border-height) solid;
        height: var(--block-height-medium);
        display: flex;
        align-items: center;
    }

    .profile__main__tabs__tab_active>button{
        border-bottom: var(--active-tab-border-color) var(--tab-border-height) solid;
    }

    .profile__main__view{
        flex: 1;
        padding: var(--indent-size);
    }

</style>
