<template lang="pug">
div
    z-input(v-model="displayed_name" label="Отображаемое имя")
    z-select(v-model="skin" :items="skinsList" label="Стиль" :no_input="true" :item_key="'key'" @change="changeSkin")
    z-select(v-model="font" :items="fontsList" label="Шрифт" :no_input="true" :item_key="'key'" @change="changeFont")
    div(class="z-row")
        z-input(v-model="fontSize" @change="changeFontSize" type="Number" label="Размер шрифта" class="profile__font-size")
        z-btn(@click="saveFontSize" icon="check" :transparent="true" class="profile__font-size-save")
    z-color-select(v-model="text_color" label="Цвет текста")
    z-color-select(v-model="name_color" label="Цвет ника")
    div(class="z-spacer")
    z-btn(:loading="isSaving" @click="saveUser") СОХРАНИТЬ
</template>

<script setup>
import { ref } from 'vue'
import axios from 'axios'
import ZColorSelect from "../../components/z-color-select"
import ZSelect from "../../components/z-select"
import {skins, fonts, defaultSkin, defaultFont, defaultFontSize, getSetting} from "../../styles"

// import mitt from 'mitt'
// const emitter = mitt()
import {emitter} from '../../plugins/emitter'

import { useAuth } from '../../store/auth'
const { userData } = useAuth()

// console.log( userData )

const displayed_name = ref((userData.value.displayed_name) ? userData.value.displayed_name : userData.value.name)
const text_color = ref(userData.value.text_color)
const name_color = ref(userData.value.name_color)
const skin = ref( getSetting('skin', defaultSkin, skins))
const skinsList = ref(skins)
const font = ref( getSetting('font', defaultFont, fonts))
const fontsList = ref(fonts)
const fontSize = ref(getSetting('font_size', defaultFontSize))
const isSaving = ref(false)

function saveUser () {
    isSaving.value = true
    let user_data = {
        user_id: userData.value.id,
        displayed_name: displayed_name.value,
        text_color: text_color.value,
        name_color: name_color.value,
    }
    axios.post('/save_user/', user_data)
        .then(response => {
            isSaving.value = false
        })
        .catch(response => {
            isSaving.value = false
        })
}

function changeSkin(value){
    // console.log(value)
    localStorage.setItem('skin', value.key)
    emitter.emit('changeSettings')
}

function changeFont (value) {
    localStorage.setItem('font', value.key)
    emitter.emit('changeSettings')
}

function changeFontSize () {
    localStorage.setItem('font_size', fontSize.value)
}

function saveFontSize () {
    emitter.emit('changeSettings')
}
</script>

<style scoped>
    .profile__font-size{
        max-width: 7rem;
        margin-right: var(--indent-size)
    }

    .profile__font-size-save{
        max-height: var(--block-height-big);
    }

    .z-row{
        display: flex;
        align-items: center;
        /*justify-content: center;*/
    }

    .z-row>.z-btn{
        max-width: 2.5rem;
    }
</style>
