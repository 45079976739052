<template lang="pug">
div(:class="{'z-select': true, 'z-select-focused':isSelectOpen, 'z-select-bordered':bordered}"
     ref="refZSelectRoot"
     @click="startSelect"
     @keydown.down.prevent="moveSelected(1)"
     @keydown.up.prevent="moveSelected(-1)"
     @keyup.esc="stopSelect()"
     @keyup.enter="selectCurrent"
)
    div(class="z-select__input__label" v-html="label")
    div(class="z-select__input__container")
        div(v-show="no_input" class="z-select__input__back")
        input(v-model="currentValueSearch"
               ref="refInput"
               @focus="inputFocus"
               @blur="inputBlur"
               class="z-select__input"
               :placeholder="placeholder"
               type="search"
               autocomplete="z-select__input"
               :disabled = 'no_input'
        )
        div(v-show="bordered" class="z-select-focus-border")
        slot(name="append-inner")
            mdicon(v-show="showIcon" :name="iconName" class="z-select__icon")
    div(v-show="isSelectOpen" class="z-select__dropdown")
        ul(class="z-select__dropdown__list" ref="refList")
            li(v-for="(item, itemIndex) in displayedItems"
                :key="`item_${itemIndex}`"
                :class="{'z-select__dropdown__list__item': true, 'z-select__dropdown__list__item_active': itemIndex===selectedItemIndex}"
                @click.stop.prevent="selectItem(itemIndex)"
            )
                slot(name="item" v-bind:item="item") {{getValueTitle(item)}}
            li(v-show="!showAll && displayedItems.length !== filteredItems.length" :style="`min-height: calc(var(--block-height-medium)*${lengthDelta});`") Загрузка
        div(class="z-select__footer")
            slot(name="footer")
</template>

<script setup>
import {defineProps, defineEmits, ref, computed, watch, nextTick, onBeforeUnmount} from 'vue'

const props = defineProps({
    modelValue: {},
    label: { default: '' },
    items: {},
    item_key: { default: 'id', type: String },
    placeholder: { default: '' },
    bordered: { default: true, type: Boolean },
    showIcon: { default: true, type: Boolean },
    titleFunction: { default: null },
    no_input: { default: false, type: Boolean }
})

const emit = defineEmits(['update:modelValue', 'startSelect', 'change'])

const isFocused = ref(false)
const isSelectOpen = ref(false)
const selectedItemIndex = ref(0)
const currentValueSearch = ref(getValueTitle(props.modelValue))
const showAll = ref(false)
const iconName = computed(()=> isSelectOpen.value ? 'menu-up' : 'menu-down' )

const refInput = ref(null)
const refZSelectRoot = ref(null)
const refList = ref(null)

const filteredItems = computed(() => {
    if (currentValueSearch.value && currentValueSearch.value !== getValueTitle(props.modelValue)) {
        return props.items.filter(item => getValueTitle(item).toUpperCase().includes(currentValueSearch.value.toUpperCase()))
    } else {
        return props.items
    }
})

const displayedItems = computed(() => {
    if (!showAll.value) {
        return filteredItems.value.slice(0, 20)
    } else {
        return filteredItems.value
    }
})

const lengthDelta = computed(() => {
    if (showAll.value) {
        return 0
    } else {
        return filteredItems.value.length - displayedItems.value.length
    }
})

function getValueTitle (value) {
    if (props.titleFunction) {
        return props.titleFunction(value)
    } else {
        return (value) ? value.title : ''
    }
}


function focus () {
    refInput.value.focus()
}

function inputFocus () {
    isFocused.value = true
    currentValueSearch.value = ''//this.getValueTitle(this.currentValue);
}

function inputBlur () {
    isFocused.value = false
    // this.currentValueSearch = this.getValueTitle(this.currentValue);
}

function registerOnClickOutside () {
    window.addEventListener('click', closeOnclickOutside)
}

function destroyOnClickOutside() {
    window.removeEventListener('click', closeOnclickOutside);
}


function closeOnclickOutside (event) {
    if (isSelectOpen.value) {
        let domElement = refZSelectRoot.value
        if (domElement && !domElement.contains(event.target)) {
            stopSelect()
        }
    }
}

function startSelect () {
    // console.log('startSelect')
    if (!isSelectOpen.value) {
        isSelectOpen.value = true
        registerOnClickOutside()
        emit('startSelect')

        setTimeout(() => {
            showAll.value = true
        }, 500)
    }
}

function stopSelect () {
    if (isSelectOpen.value) {
        isSelectOpen.value = false
        destroyOnClickOutside()
        refInput.value.blur()
        currentValueSearch.value = getValueTitle(props.modelValue)
        if (selectedItemIndex.value < 30) {
            showAll.value = false
        }
    }
}

function moveSelected (direction) {
    if (isSelectOpen.value) {
        let newIndex = selectedItemIndex.value + direction
        let maxIndex = filteredItems.value.length - 1
        if (newIndex >= 0 && newIndex <= maxIndex) {
            selectedItemIndex.value = newIndex
            const scrollItems = () => {
                refList.value.querySelector('.z-select__dropdown__list__item_active').scrollIntoView()
            }
            if (direction === -1) {
                nextTick(() => scrollItems())
            } else {
                scrollItems()
            }
        }
    }
}

function selectCurrent () {
    if (!isSelectOpen.value) {
        return
    }
    if (filteredItems.value.length > 0) {
        let value = filteredItems.value[selectedItemIndex.value]

        // console.log(value)

        emit('update:modelValue', value)
        emit('change', value)

        stopSelect()
        // this.currentValue = value
        currentValueSearch.value = getValueTitle(value)
    }
    isSelectOpen.value = false
}

function selectItem (index) {
    selectedItemIndex.value = index
    selectCurrent()
    isSelectOpen.value = false
}

function findCurrent () {
    let index = props.items.findIndex(item => (props.modelValue && item[props.item_key] === props.modelValue[props.item_key]))
    selectedItemIndex.value = Math.max(index, 0)
}

watch(
    () => props.modelValue,
    (newValue) => {
        currentValueSearch.value = getValueTitle(newValue)
    }
)

onBeforeUnmount(()=>destroyOnClickOutside())
findCurrent()


    //     watch:{
    //         value(newValue){
    //             this.currentValue = newValue;
    //             this.currentValueSearch = this.getValueTitle(this.currentValue );
    //             this.findCurrent();
    //         },
    //         items(newValue){
    //             this.findCurrent();
    //         },
    //     },
    //     created() {
    //         this.findCurrent();
    //     }
    // }
</script>

<style>
    .z-select{
        margin-bottom: var(--indent-size);
        width: 100%;
        position: relative;
    }

    .z-select__input__label{
        font-size: var(--font-size-small);
    }

    .z-select-focused > .z-select__input__label{
        color: var(--primary-color);
    }

    .z-select__input{
        padding-bottom: var(--indent-size);
        padding-top: var(--indent-size);
        width: 100%;
        color: var(--regular-text-color);
    }

    .z-select__input__container{
        display: flex;
        height: 100%;
    }

    .z-select__input__back{
        position:absolute;
        left:0;
        right:0;
        top:0;
        bottom:0;
        /*z-index:-1;*/
    }

    .z-select-bordered .z-select__input{
        border-bottom: 1px solid var(--regular-text-color);
    }

    .z-select .z-select__input ~ .z-select-focus-border{
        position: absolute;
        bottom: 0;
        left: 50%;
        width: 0;
        height: 2px;
        background-color: var(--primary-color);
        transition: 0.4s;
    }

    .z-select-focused .z-select__input ~ .z-select-focus-border{
        width: 100%;
        transition: 0.3s; left: 0;
    }

    .z-select__icon{
        position: absolute;
        right: 0;
        bottom: 0.35rem;
    }

    .z-select__dropdown{
        position: absolute;
        background: var(--editor-background);
        z-index: 2;
        box-shadow: var(--droopdown-box-shadow);
        width: 100%;
    }

    .z-select__dropdown__list{
        overflow-y: auto;
        max-height: calc(var(--block-height-medium)*10);
        cursor: default;
    }

    .z-select__dropdown__list__item{
        min-height: var(--block-height-medium);
        cursor: pointer;
        padding-left: var(--indent-size);
        padding-right: var(--indent-size);
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-bottom: 0.1rem;
    }

    .z-select__dropdown__list__item:hover{
        background: var(--list-hover-background);
    }

    .z-select__dropdown__list__item_active,
    .z-select__dropdown__list__item_active:hover
    {
        background: var(--list-selected-background);
    }
</style>
