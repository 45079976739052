<template>
    <div>
        <z-input label="Новый пароль" v-model="new_password"></z-input>
        <div class="z-spacer"></div>
        <z-btn @click="setPassword" :disable="!new_password">УСТАНОВИТЬ</z-btn>
    </div>
</template>

<script>
    import {notifySuccess, notifyErrors} from '../../plugins/notify'

    export default {
        name: "Profile-user",
        data(){return{
            new_password: '',
            isSaving: false
        }},
        methods:{
            setPassword(){
                this.isSaving = true;
                let user_data = {
                    new_password: this.new_password
                };
                this.axios.post('/new_password/', user_data)
                    .then(response => {
                        this.isSaving = false;
                        this.new_password = '';
                        notifySuccess(['Пароль успешно изменен'], 'main')
                    })
                    .catch(response => {
                        this.isSaving = false;
                        notifyErrors(response, 'main', 'Ошибка сброса пароля')
                    })
            }
        }
    }
</script>

<style scoped>

</style>
